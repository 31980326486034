var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "excel-import" },
    [
      _vm._t("filter"),
      _vm.templateFile && _vm.exampleUrl
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top",
                  value: {
                    content: this.templateFile,
                    delay: { show: 100, hide: 100 },
                  },
                  expression:
                    "{\n      content: this.templateFile,\n      delay: { show: 100, hide: 100 }\n    }",
                  modifiers: { top: true },
                },
              ],
              staticClass: "pb-6",
            },
            [
              _c("label", { staticClass: "font-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("modelo-de-importacao")) + " "),
              ]),
              _c("a", { attrs: { download: "", href: _vm.exampleUrl } }, [
                _vm._v(" " + _vm._s(_vm.templateFile) + " "),
              ]),
            ]
          )
        : _vm._e(),
      _c("input", {
        ref: "fileInput",
        staticClass: "hidden",
        attrs: { type: "file", accept: _vm.accept },
        on: { change: _vm.handleClick },
      }),
      _c(
        "div",
        {
          staticClass:
            "px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl",
          on: {
            click: function ($event) {
              return _vm.$refs.fileInput.click()
            },
            drop: _vm.handleDrop,
            dragover: _vm.handleDragover,
            dragenter: _vm.handleDragover,
          },
        },
        [
          _c("feather-icon", {
            staticClass: "block",
            attrs: {
              icon: "UploadCloudIcon",
              svgClasses: "h-16 w-16 stroke-current text-grey",
            },
          }),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "arraste-e-solte-aqui-o-arquivo-para-importar-ou-selecione-aqui-para"
                )
              ) + " "
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "font-medium text-primary",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$refs.fileInput.click()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buscar-no-computador")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }